import * as React from "react";
import styles from "./app.module.scss";
import Router from "./router/router";
import { CssBaseline, PaletteMode, ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";
import { ThemeModeContext } from "./context/ThemeModeContext";

interface IAppProps { }

const App: React.FunctionComponent<IAppProps> = (props) => {
  const [themeMode, setThemeMode] = React.useState<PaletteMode>('dark');
  let theme = React.useMemo(() => createTheme({
    palette: {
      primary: {
        main: '#86c232',
      },
      mode: themeMode
    },
    typography: {
      fontFamily: 'Poppins',
      h1: {
        fontSize: '3.5rem'
      },
      h6: {
        fontWeight: 'normal',
        // color: 'GrayText'
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      }
    }
  }), [themeMode]);
  theme = responsiveFontSizes(theme);
  return (
    <ThemeModeContext.Provider value={{ themeMode: themeMode, setTheme: setThemeMode }} >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <main className={styles.main}>
          <Router />
        </main>
      </ThemeProvider>
    </ThemeModeContext.Provider>
  );
};

export default App;
