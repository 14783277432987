import { PaletteMode } from "@mui/material";
import * as React from "react";

export interface IThemeMode {
  themeMode: PaletteMode;
  setTheme?: (val: PaletteMode) => void;
}

export const ThemeModeContext = React.createContext<IThemeMode>({
  themeMode: "dark",
});
