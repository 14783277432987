
import { useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import fbAuth from "firebase/auth";
import { auth } from "../firebase/firebaseConfig";

interface AuthProviderProps {
    children: React.JSX.Element
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<fbAuth.User | null>(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
            setUser(firebaseUser);
        });

        return unsubscribe;
    }, []);

    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};