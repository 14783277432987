import { FunctionComponent, lazy, Suspense, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CircularProgress } from '@mui/material'
import ProtectedRoute from './protectedRoute';
import { AuthContext } from '../context/AuthContext';

interface IRouterProps {
}

function lazyLoadRoutes(ComponentName: string) {
    const LazyElement = lazy(() => import(`../views/${ComponentName}/${ComponentName}.tsx`));

    // Wrapping around the suspense component is mandatory as it shows the user a progress indicator whille the page is loading.
    return (
        <Suspense fallback={<CircularProgress />}>
            <LazyElement />
        </Suspense>
    );
}

// const LazyHome = lazy(() => import('../views/home/Home'));

const Router: FunctionComponent<IRouterProps> = (props) => {
    const user = useContext(AuthContext);
    return (
        <Routes>
            <Route path='/' element={lazyLoadRoutes('Home')} />
            <Route path='/dashboard' element={lazyLoadRoutes('Dashboard')} />
            <Route path='/admin' element={<ProtectedRoute children={lazyLoadRoutes('Admin')} isAuthenticated={false} />} />
            <Route path='/signin' element={!user ? lazyLoadRoutes('Signin') : lazyLoadRoutes('Dashboard')} />
            <Route path='/signup' element={lazyLoadRoutes('Signup')} />
            <Route path='/enroll' element={lazyLoadRoutes('Enroll')} />
            <Route path='/settings' element={lazyLoadRoutes('Settings')} />
        </Routes>
    );
};

export default Router;
