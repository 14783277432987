import * as React from 'react';
import { Navigate } from 'react-router-dom';

interface IProtectedRouteProps {
    children: React.JSX.Element
    isAuthenticated: Boolean
}

const ProtectedRoute: React.FunctionComponent<IProtectedRouteProps> = ({ children, isAuthenticated }) => {
    if (isAuthenticated) {
        return children;
    }
    return <Navigate to="/signin" replace />
};

export default ProtectedRoute;
